<template>
  <div class="article-editor" v-if="canEdit && editedArticle">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <b-form @submit.prevent="onSave">
      <b-row>
        <b-col cols="8">
          <b-form-group label-for="name" label="Nom du jeu *">
            <b-form-input type="text" v-model="editedArticle.name" id="name" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-for="category" label="Catégorie">
            <b-input-group>
              <b-select v-model="editedArticle.category.id">
                <b-select-option v-for="category of categories" :key="category.id" :value="category.id">
                  {{ category.name }}
                </b-select-option>
              </b-select>
              <b-input-group-append v-if="canAddCategory">
                <b-button
                  variant="secondary"
                  @click.prevent="showCategoryModal"
                  title="Ajouter une catégorie"
                  v-b-tooltip
                >
                  <i class="fa fa-plus"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <b-form-group label-for="classification" label="Classement ESAR">
                <b-input-group>
                  <b-select v-model="editedArticle.classification.id">
                    <b-select-option v-for="classification of classifications" :key="classification.id" :value="classification.id">
                      {{ classification.name }}
                    </b-select-option>
                  </b-select>
                  <b-input-group-append v-if="canAddClassification">
                    <b-button
                      variant="secondary"
                      @click.prevent="showClassificationModal"
                      title="Ajouter une classification"
                      v-b-tooltip
                    >
                      <i class="fa fa-plus"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="type" label="Type">
                <b-input-group>
                  <b-select v-model="editedArticle.type.id">
                    <b-select-option v-for="type of types" :key="type.id" :value="type.id">
                      {{ type.name }}
                    </b-select-option>
                  </b-select>
                  <b-input-group-append v-if="canAddType">
                    <b-button
                      variant="secondary"
                      @click.prevent="showTypeModal"
                      title="Ajouter un type"
                      v-b-tooltip
                    >
                      <i class="fa fa-plus"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="location" label="Emplacement">
                <b-input-group>
                  <b-select v-model="editedArticle.location.id">
                    <b-select-option v-for="location of locations" :key="location.id" :value="location.id">
                      {{ location.name }}
                    </b-select-option>
                  </b-select>
                  <b-input-group-append v-if="canAddLocation">
                    <b-button
                      variant="secondary"
                      @click.prevent="showLocationModal(editedArticle)"
                      title="Ajouter un type"
                      v-b-tooltip
                    >
                      <i class="fa fa-plus"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label-for="editor" label="Éditeur">
                <b-input-group>
                  <b-select v-model="editedArticle.editor.id">
                    <b-select-option v-for="editor of editors" :key="editor.id" :value="editor.id">
                      {{ editor.name }}
                    </b-select-option>
                  </b-select>
                  <b-input-group-append v-if="canAddEditor">
                    <b-button
                      variant="secondary"
                      @click.prevent="showEditorModal"
                      title="Ajouter un éditeur"
                      v-b-tooltip
                    >
                      <i class="fa fa-plus"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="distributor" label="Distributeur">
                <b-input-group>
                  <b-select v-model="editedArticle.distributor.id">
                    <b-select-option v-for="distributor of distributors" :key="distributor.id" :value="distributor.id">
                      {{ distributor.name }}
                    </b-select-option>
                  </b-select>
                  <b-input-group-append v-if="canAddDistributor">
                    <b-button
                      variant="secondary"
                      @click.prevent="showDistributorModal"
                      title="Ajouter un distributeur"
                      v-b-tooltip
                    >
                      <i class="fa fa-plus"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="link" label="Lien">
                <b-form-input type="text" v-model="editedArticle.link" id="link"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label-for="editionYear" label="Année édition">
                <b-form-input step="1" min="0" type="number" v-model="editedArticle.editionYear" id="usage1"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="usage1" label="Nb de joueurs">
                <b-form-input type="text" v-model="editedArticle.usage1" id="usage1"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="usage2" label="Durée">
                <b-form-input type="text" v-model="editedArticle.usage2" id="usage2"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="theme" label="Thème">
                <b-textarea v-model="editedArticle.theme" id="theme"></b-textarea>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col>
              <b-form-group label-for="usage3" label="But du jeu">
                <b-textarea v-model="editedArticle.usage3" id="usage3"></b-textarea>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label-for="description" label="Description">
                <b-textarea v-model="editedArticle.description" id="description"></b-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-group label-for="itemsLength" label="Nombre d'exemplaires">
            <b-input-group>
              <b-form-input type="text" disabled :value="editedArticle.items.length" id="itemsLength"></b-form-input>
              <b-input-group-append v-if="canAddCategory">
                <b-button
                  variant="secondary"
                  @click.prevent="addItem"
                  title="Ajouter un exemplaire"
                  v-b-tooltip
                >
                  <i class="fa fa-plus"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div v-for="item of editedArticle.items" :key="item.id" class="article-item">
            <b-row>
              <b-col>
                <b-form-group
                  v-if="item.id > 0"
                  :label-for="'isAvailable' + item.id"
                  label=" "
                  description="si décoché, l'exemplaire n'existe plus et ne peut plus être emprunté"
                >
                  <b-form-checkbox v-model="item.isAvailable" :id="'isAvailable' + item.id">
                    Cet exemplaire existe toujours
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  v-else
                  :label-for="'isAvailable' + item.id"
                  label=" "
                  description="si décoché, l'exemplaire sera supprimé"
                >
                  <b-form-checkbox
                    v-model="item.isAvailable" :id="'isAvailable' + item.id" @change="removeItem(item)"
                  >
                    Ajouter ce nouvel exemplaire
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group :label-for="'ref' + item.id" label="Référence *">
                  <b-form-input
                    required
                    type="text"
                    v-model="item.reference"
                    :id="'ref' + item.id"
                  ></b-form-input>
                  <div class="text-right small-text">
                    <a href @click.prevent="nextReference(item)" v-if="!item.reference">
                      n° suivant
                    </a>
                  </div>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label-for="'status' + item.id" label="État">
                  <b-form-input type="text" v-model="item.status" :id="'status' + item.id"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label-for="'location' + item.id"
                  label="Emplacement"
                  description="si différent de celle définie sur le jeu"
                >
                  <b-input-group>
                    <b-select v-model="item.location.id">
                      <b-select-option v-for="location of locations" :key="location.id" :value="location.id">
                        {{ location.name }}
                      </b-select-option>
                    </b-select>
                    <b-input-group-append v-if="canAddLocation">
                      <b-button
                        variant="secondary"
                        @click.prevent="showLocationModal(item)"
                        title="Ajouter un type"
                        v-b-tooltip
                      >
                        <i class="fa fa-plus"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group :label-for="'description' + item.id" label="Description">
                  <b-textarea v-model="item.description" :id="'description' + item.id"></b-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group :label-for="'boughtOn' + item.id" label="Acheté le">
                  <b-form-input type="date" v-model="item.boughtOn" :id="'boughtOn' + item.id"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label-for="'buyingPrice' + item.id" label="Prix d'achat">
                  <decimal-input :id="'buyingPrice' + item.id" v-model="item.buyingPrice"></decimal-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label-for="'provider' + item.id" label="Fournisseur">
                  <b-input-group>
                    <b-select v-model="item.provider.id">
                      <b-select-option v-for="provider of providers" :key="provider.id" :value="provider.id">
                        {{ provider.name }}
                      </b-select-option>
                    </b-select>
                    <b-input-group-append v-if="canAddProvider">
                      <b-button
                        variant="secondary"
                        @click.prevent="showProviderModal(item)"
                        title="Ajouter un fournisseur"
                        v-b-tooltip
                      >
                        <i class="fa fa-plus"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <a href @click.prevent="addContent()" class="btn btn-primary">
                <i class="fa fa-plus"></i> Ajouter un élément
              </a>
            </b-col>
          </b-row>
          <div v-for="content of editedArticle.contents" :key="content.id" class="article-content">
            <b-row>
              <b-col>
                <b-form-group :label-for="'content' + content.id" label="Nom *">
                  <b-form-input required type="text" v-model="content.name" :id="'content' + content.id">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label-for="'contentDesc' + content.id" label="Description">
                  <b-form-input type="text" v-model="content.description" :id="'contentDesc' + content.id">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="text-right" cols="2">
                <br />
                <a href @click.prevent="removeContent(content)" class="btn btn-danger">
                  <i class="fa fa-trash"></i> Supprimer
                </a>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <div class="bottom-bar">
        <b-row>
          <b-col cols="8">
            <a
              class="btn btn-danger"
              href
              @click.prevent="onArchive"
              v-if="canDelete"
              :disabled="isLoading(loadingName)"
            >
              Archiver
            </a>
          </b-col>
          <b-col class="text-right">
            <a
              class="btn btn-secondary cancel-button"
              href
              @click.prevent="onCancel"
              :disabled="isLoading(loadingName)"
            >
              Annuler
            </a>
            &nbsp;
            <b-button
              type="submit"
              variant="primary"
              :disabled="isLoading(loadingName) || !isValid()"
            >
              Enregistrer
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-form>
    <confirm-modal
      v-if="canDelete"
      name="archive-article"
      title="Archivage du jeu"
      :text="archiveArticleConfirmText"
      @confirmed="archiveArticle()"
    ></confirm-modal>
    <add-category-modal
      :modal-id="addCategoryModal"
      @done="onCategoryAdded($event)"
    >
    </add-category-modal>
    <add-type-modal
      :modal-id="addTypeModal"
      @done="onTypeAdded($event)"
    >
    </add-type-modal>
    <add-classification-modal
      :modal-id="addClassificationModal"
      @done="onClassificationAdded($event)"
    >
    </add-classification-modal>
    <add-location-modal
      :modal-id="addLocationModal"
      @done="onLocationAdded($event)"
    >
    </add-location-modal>
    <add-editor-modal
      :modal-id="addEditorModal"
      @done="onEditorAdded($event)"
    >
    </add-editor-modal>
    <add-distributor-modal
      :modal-id="addDistributorModal"
      @done="onDistributorAdded($event)"
    >
    </add-distributor-modal>
    <add-provider-modal
      :modal-id="addProviderModal"
      @done="onProviderAdded($event)"
    >
    </add-provider-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import router from '@/router'
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import LoadingGif from '@/components/Controls/LoadingGif'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import {
  makeStoreArticle, makeStoreArticleCategory, makeStoreArticleType, makeLocation, makeEditor, makeDistributor,
  makeClassification, makeProvider, makeStoreItem, makeStoreArticleContent
} from '@/types/store'
import AddCategoryModal from '@/components/Store/AddCategoryModal.vue'
import AddClassificationModal from '@/components/Store/AddClassificationModal.vue'
import AddLocationModal from '@/components/Store/AddLocationModal.vue'
import AddEditorModal from '@/components/Store/AddEditorModal.vue'
import AddDistributorModal from '@/components/Store/AddDistributorModal.vue'
import AddProviderModal from '@/components/Store/AddProviderModal.vue'
import AddTypeModal from '@/components/Store/AddTypeModal.vue'
import DecimalInput from '@/components/Controls/DecimalInput.vue'

export default {
  name: 'article-editor',
  props: {
    article: Object,
  },
  mixins: [BackendMixin],
  components: {
    DecimalInput,
    AddDistributorModal,
    AddEditorModal,
    AddLocationModal,
    AddClassificationModal,
    AddCategoryModal,
    AddTypeModal,
    AddProviderModal,
    LoadingGif,
    ConfirmModal,
  },
  data() {
    return {
      loadingName: 'article-editor',
      categories: [],
      types: [],
      classifications: [],
      locations: [],
      editors: [],
      distributors: [],
      providers: [],
      editedArticle: null,
      addCategoryModal: 'bv-add-store-category-modal',
      addTypeModal: 'bv-add-store--type-modal',
      addLocationModal: 'bv-add-store-location-modal',
      addClassificationModal: 'bv-add-store-classification-modal',
      addEditorModal: 'bv-add-store-editor-modal',
      addDistributorModal: 'bv-add-store-distributor-modal',
      addProviderModal: 'bv-add-store-provider-modal',
      selectedObj: null,
      newItemsCounter: 0,
      newContentsCounter: 0,
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    article: function() {
      this.init()
    },
  },
  computed: {
    canEdit() {
      if (this.article.id) {
        return this.hasPerm('store.change_article')
      } else {
        return this.hasPerm('store.add_article')
      }
    },
    canDelete() {
      if (this.article.id) {
        return this.hasPerm('store.delete_article')
      } else {
        return false
      }
    },
    canAddCategory() {
      return this.hasPerm('store.add_articlecategory')
    },
    canAddClassification() {
      return this.hasPerm('store.add_articleclassification')
    },
    canAddType() {
      return this.hasPerm('store.add_articletype')
    },
    canAddLocation() {
      return this.hasPerm('store.add_location')
    },
    canAddEditor() {
      return this.hasPerm('store.add_editor')
    },
    canAddDistributor() {
      return this.hasPerm('store.add_distributor')
    },
    canAddProvider() {
      return this.hasPerm('store.add_provider')
    },
    archiveArticleConfirmText() {
      return 'Archiver ' + this.article.name
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async init() {
      this.cloneArticleForEdition()
      await this.loadTypes()
      await this.loadCategories()
      await this.loadClassifications()
      await this.loadEditors()
      await this.loadDistributors()
      await this.loadProviders()
      await this.loadLocations()
    },
    cloneArticleForEdition() {
      this.editedArticle = { ...this.article, }
      this.editedArticle.items = []
      for (const item of this.article.items) {
        this.editedArticle.items.push({ ...item, })
      }
      this.editedArticle.contents = []
      for (const content of this.article.contents) {
        this.editedArticle.contents.push({ ...content, })
      }
      if ((this.editedArticle.id === 0) && (this.editedArticle.items.length === 0)) {
        this.addItem()
      }
    },
    isValid() {
      if (!this.editedArticle.name) {
        return false
      }
      for (const item of this.editedArticle.items) {
        if (!item.reference) {
          return false
        }
      }
      for (const content of this.editedArticle.contents) {
        if (!content.name) {
          return false
        }
      }
      return true
    },
    async onSave() {
      this.startLoading(this.loadingName)
      try {
        const data = {
          name: this.editedArticle.name,
          category: this.editedArticle.category.id || null,
          type: this.editedArticle.type.id || null,
          location: this.editedArticle.location.id || null,
          classification: this.editedArticle.classification.id || null,
          editor: this.editedArticle.editor.id || null,
          distributor: this.editedArticle.distributor.id || null,
          description: this.editedArticle.description,
          theme: this.editedArticle.theme,
          usage1: this.editedArticle.usage1,
          usage2: this.editedArticle.usage2,
          usage3: this.editedArticle.usage3,
          link: this.editedArticle.link,
          'age_from': this.editedArticle.age_from,
          'age_to': this.editedArticle.age_to,
          items: [],
          contents: [],
          edition_year: this.editedArticle.editionYear || null,
        }
        for (const item of this.editedArticle.items) {
          const itemData = {
            reference: item.reference,
            is_available: item.isAvailable,
            provider: item.provider.id || null,
            location: item.location.id || null,
            bought_on: item.bought_on ? moment(item.bought_on).format('YYYY-MM-DD') : null,
            buying_price: item.buying_price,
            description: item.description,
            status: item.status,
          }
          if (item.id > 0) {
            itemData.id = item.id
          }
          data.items.push(itemData)
        }
        for (const content of this.editedArticle.contents) {
          const contentData = {
            name: content.name,
            description: content.description,
          }
          if (content.id > 0) {
            contentData.id = content.id
          }
          data.contents.push(contentData)
        }
        let url = '/store/api/articles/'
        let method = 'post'
        let message = 'L\'article a été ajoutée'
        if (this.article.id) {
          url += this.article.id + '/'
          method = 'patch'
          message = 'L\'article a été mis à jour'
        }
        let backendApi = new BackendApi(method, url)
        let resp = await backendApi.callApi(data)
        let article = makeStoreArticle(resp.data)
        this.$emit('done', { article: article, })
        await this.addSuccess(message)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    getInitialValue(array, expected) {
      const expectedId = (expected === null) ? 0 : expected.id
      for (let elt of array) {
        if (elt.id === expectedId) {
          return elt
        }
      }
      return null
    },
    async loadCategories() {
      const backendApi = new BackendApi('get', '/store/api/categories/')
      try {
        let resp = await backendApi.callApi()
        this.categories = [makeStoreArticleCategory()].concat(resp.data.map(elt => makeStoreArticleCategory(elt)))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadTypes() {
      const backendApi = new BackendApi('get', '/store/api/types/')
      try {
        let resp = await backendApi.callApi()
        this.types = [makeStoreArticleType()].concat(resp.data.map(elt => makeStoreArticleType(elt)))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadClassifications() {
      const backendApi = new BackendApi('get', '/store/api/classifications/')
      try {
        let resp = await backendApi.callApi()
        this.classifications = [makeClassification()].concat(resp.data.map(elt => makeClassification(elt)))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadLocations() {
      const backendApi = new BackendApi('get', '/store/api/locations/')
      try {
        let resp = await backendApi.callApi()
        this.locations = [makeLocation()].concat(resp.data.map(elt => makeLocation(elt)))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadEditors() {
      const backendApi = new BackendApi('get', '/store/api/editors/')
      try {
        let resp = await backendApi.callApi()
        this.editors = [makeEditor()].concat(resp.data.map(elt => makeEditor(elt)))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadDistributors() {
      const backendApi = new BackendApi('get', '/store/api/distributors/')
      try {
        let resp = await backendApi.callApi()
        this.distributors = [makeDistributor()].concat(resp.data.map(elt => makeDistributor(elt)))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadProviders() {
      const backendApi = new BackendApi('get', '/store/api/providers/')
      try {
        let resp = await backendApi.callApi()
        this.providers = [makeProvider()].concat(resp.data.map(elt => makeProvider(elt)))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    showCategoryModal() {
      this.$bvModal.show(this.addCategoryModal)
    },
    async onCategoryAdded(event) {
      const newObj = makeStoreArticleCategory(event)
      this.editedArticle.category = newObj
      this.categories.push(newObj)
    },
    showTypeModal() {
      this.$bvModal.show(this.addTypeModal)
    },
    async onTypeAdded(event) {
      const newObj = makeStoreArticleType(event)
      this.editedArticle.type = newObj
      this.types.push(newObj)
    },
    showClassificationModal() {
      this.$bvModal.show(this.addClassificationModal)
    },
    async onClassificationAdded(event) {
      const newObj = makeClassification(event)
      this.editedArticle.classification = newObj
      this.classifications.push(newObj)
    },
    showLocationModal(obj) {
      this.selectedObj = obj
      this.$bvModal.show(this.addLocationModal)
    },
    async onLocationAdded(event) {
      const newObj = makeLocation(event)
      if (this.selectedObj) {
        this.selectedObj.location = newObj
      }
      this.locations.push(newObj)
    },
    showEditorModal() {
      this.$bvModal.show(this.addEditorModal)
    },
    async onEditorAdded(event) {
      const newObj = makeEditor(event)
      this.editedArticle.editor = newObj
      this.editors.push(newObj)
    },
    showDistributorModal() {
      this.$bvModal.show(this.addDistributorModal)
    },
    async onDistributorAdded(event) {
      const newObj = makeDistributor(event)
      this.editedArticle.distributor = newObj
      this.distributors.push(newObj)
    },
    showProviderModal(obj) {
      this.selectedObj = obj
      this.$bvModal.show(this.addProviderModal)
    },
    async onProviderAdded(event) {
      const newObj = makeProvider(event)
      if (this.selectedObj) {
        this.selectedObj.provider = newObj
      }
      this.providers.push(newObj)
    },
    onArchive() {
      if (!this.isLoading(this.loadingName) && this.article && this.article.id) {
        this.$bvModal.show('bv-confirm-modal:archive-article')
      }
    },
    async nextReference(item) {
      try {
        const url = '/store/api/next-reference/'
        let backendApi = new BackendApi('get', url)
        const resp = await backendApi.callApi()
        const next = resp.data['next_reference']
        if (!item.reference && next) {
          item.reference = next
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async archiveArticle() {
      if (this.article.id) {
        this.startLoading(this.loadingName)
        try {
          const url = '/store/api/articles/' + this.article.id + '/'
          let backendApi = new BackendApi('delete', url)
          await backendApi.callApi()
          await this.addSuccess('L\'article ' + this.article.name + ' a été archivée')
          await router.push({ name: 'store-articles', })
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    onCancel() {
      if (!this.isLoading(this.loadingName)) {
        this.$emit('cancel')
      }
    },
    addItem() {
      this.newItemsCounter += 1
      this.editedArticle.items.push(
        makeStoreItem({ id: -1 * this.newItemsCounter, 'is_available': true, })
      )
    },
    removeItem(item) {
      if ((item.id < 0) && !item.isAvailable) {
        const index = this.editedArticle.items.map(elt => elt.id).indexOf(item.id)
        if (index >= 0) {
          this.editedArticle.items.splice(index, 1)
        }
      }
    },
    addContent() {
      this.newContentsCounter += 1
      this.editedArticle.contents.push(
        makeStoreArticleContent({ id: -1 * this.newContentsCounter, })
      )
    },
    removeContent(content) {
      const index = this.editedArticle.contents.map(elt => elt.id).indexOf(content.id)
      if (index >= 0) {
        this.editedArticle.contents.splice(index, 1)
      }
    },
  },
}
</script>

<style lang="less">
.bottom-bar {
  margin-top: 10px;
  padding-top: 10px;
  border-top: solid 1px #222;
}
.article-item {
  padding: 5px;
  border: solid 1px #ccc;
  border-radius: 4px;
  background: #f0f0f0;
  margin-bottom: 10px;
}
</style>
