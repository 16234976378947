<template>
  <span class="add-category-modal" v-if="canAdd">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Ajouter"
      :ok-disabled="!name"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-location-dot"></i> Ajout d'une catégorie</b>
      </template>
      <b-row>
        <b-col>
          <b-form-group label="Nom" label-for="name">
            <b-form-input id="name" v-model="name"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>

import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'

export default {
  name: 'add-category-modal',
  mixins: [BackendMixin],
  props: {
    modalId: String,
  },
  data() {
    return {
      name: '',
    }
  },
  computed: {
    canAdd() {
      return this.hasPerm('store.add_articlecategory')
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      this.name = ''
    },
    async onSave() {
      const url = '/store/api/categories/'
      const data = {
        name: this.name,
      }
      let backendApi = new BackendApi('post', url)
      try {
        const resp = await backendApi.callApi(data)
        await this.addSuccess('La catégorie a été ajoutée')
        this.$emit('done', resp.data)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.$bvModal.hide(this.modalId)
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
</style>
